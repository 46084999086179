export const SparklyTrend = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3242_12582)">
      <path
        d="M18.5269 13.6198L15.8276 12.3956L18.5269 11.1714L19.7586 8.4886L20.9904 11.1714L23.6897 12.3956L20.9904 13.6198L19.7586 16.3026L18.5269 13.6198ZM5.34484 18.9072L6.57656 16.2244L9.27587 15.0002L6.57656 13.776L5.34484 11.0933L4.11311 13.776L1.4138 15.0002L4.11311 16.2244L5.34484 18.9072ZM11.2414 12.3956L12.6697 9.25697L15.8276 7.83744L12.6697 6.4179L11.2414 3.2793L9.81311 6.4179L6.65518 7.83744L9.81311 9.25697L11.2414 12.3956ZM6.00001 27.3723L13.8621 19.5453L19.1035 24.7546L30.2414 12.3044L28.3938 10.4681L19.1035 20.8477L13.8621 15.6384L4.03449 25.4188L6.00001 27.3723Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3242_12582">
        <rect
          width="31.4483"
          height="31.2558"
          fill="white"
          transform="translate(0.103455 0.673828)"
        />
      </clipPath>
    </defs>
  </svg>
);
